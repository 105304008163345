* {
  font-family: 'Manulife JH Sans' !important;
  color: #282b3e;
  letter-spacing: normal;
}

path {
  color: inherit;
}

.App {
  text-align: left;
  display: flex;
  min-height: 100vh;
}

.App .main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App .router-wrapper {
  height: 100%;
  /* min-height: calc(100vh - 82px - 363px); */
}

/* Hide built in password eye icon in IE and Edge */
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
